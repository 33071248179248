<template lang="pug">
ui-dialog(
  modal
  closable
  width="480px"
  :header="getHeaderLabel()"
  :visible-prop="show"
  @content:hide="hide"
)
  template(#content)
    auth-by-login(
      v-if="mode === 'authByLogin'"
      :email="email"
      @set:email="email = $event"
      @set:mode="$emit('update:mode', $event)"
    )
    registration(
      v-else-if="mode === 'registration'"
      @set:email="email = $event"
      @set:mode="$emit('update:mode', $event)"
      @show:result="showResult"
    )
    password-recovery(
      v-else-if="mode === 'passwordRecovery'"
      :email="email"
      @set:email="email = $event"
      @set:mode="$emit('update:mode', $event)"
      @show:result="showResult"
    )
    result-message(
      v-else-if="mode === 'resultMessage'"
      :email="email"
      :mode="resultMode"
      :status="resultStatus"
      @close:dialog="hide"
      @set:mode="$emit('update:mode', $event)"
    )
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

import AuthByLogin from "@/components/authorization/dialogContent/AuthByLogin.vue";
import Registration from "@/components/authorization/dialogContent/Registration.vue";
import PasswordRecovery from "@/components/authorization/dialogContent/PasswordRecovery.vue";
import ResultMessage from "@/components/authorization/dialogContent/ResultMessage.vue";
import UiDialog from "@/components/ui/dialog/UiDialog.vue";

import type { PropType } from "vue";
import type { ModeType } from "@/use/auth/useLandingLogin";

export default defineComponent({
  name: "AuthorizationFormDialog",
  components: {
    UiDialog,
    ResultMessage,
    PasswordRecovery,
    AuthByLogin,
    Registration,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String as PropType<ModeType>,
      default: '',
    }
  },
  setup(props, context) {

    const email = ref('');

    const resultStatus = ref<'success'|'error'>();
    const resultMode = ref<'recovery'|'register'>();

    function hide() {
      email.value = ''
      context.emit('update:show', false)
    }

    function getHeaderLabel() {
      switch (props.mode) {
        case 'authByLogin':
        case 'passwordRecovery': return 'Вход в аккаунт';
        case 'registration': return 'Создание аккаунта';
        case 'resultMessage': return resultStatus.value === 'success' ? 'Успешно' : 'Ошибка';
        default: return '';
      }
    }

    function showResult(payload: { s: 'success'|'error', m: 'recovery'|'register' }) {
      resultStatus.value = payload.s
      resultMode.value = payload.m;
      context.emit('update:mode', 'resultMessage')
    }

    return {
      email,
      resultStatus,
      resultMode,
      hide,
      getHeaderLabel,
      showResult,
    };
  },
});
</script>

<style scoped lang="scss">
</style>
