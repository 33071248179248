<template lang="pug">
.auth
  .auth__header
    .title(@click="$emit('set:mode', 'authByLogin')")
      ui-icon(:size="20" :icon="UiIconNames.Icon_Back")
      span.label Восстановление пароля
    span.description Пожалуйста, введите свою электронную почту. На указанный адрес будет отправлено письмо сброса пароля.
  .auth__content(v-loading="loader")
    validation-row(
      ref="refEmail"
      required
      not-empty
      type-email
      v-model="email"
      placeholder="Введите email"
      label="Email"
      autocomplete="username"
      @error="errorChecker('email')"
      @correct="correctChecker('email')"
    )
    span.error-message(v-if="message") {{ message }}
  .auth__actions
    ui-button.full-width(:loader="loader" @click="validate") Восстановление пароля
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useForm } from "@/use/other/useForm";
import { useApi } from "~/use/api/useApi";

import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiButton from "@/components/ui/button/UiButton.vue";
import ValidationRow from "@/components/ui/form/input/ValidationRow.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "PasswordRecovery",
  components: {
    UiIcon,
    UiButton,
    ValidationRow,
  },
  emits: [
    'set:mode',
    'set:email',
    'show:result',
  ],
  props: {
    email: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {

    const refEmail = ref();

    const email = ref(props.email || '');
    const message = ref('');
    const loader = ref(false);

    function validate() {
      refEmail.value.validate()
      validateForm()
    }

    function saveFormContinue() {
      loader.value = true

      useApi().security.resetPassword(email.value)
        .then(() => {
          context.emit('set:email', email.value)
          context.emit('show:result', { s: 'success', m: 'recovery' })
        })
        .catch((error) => {
          if (error?.response?.status === 404) message.value = 'Указанный адрес электронной почты не зарегистрирован в системе'
          else message.value = 'Произошла ошибка, попробуйте позднее'
        })
        .finally(() => loader.value = false)
    }

    const { validateForm, errorChecker, correctChecker } = useForm(saveFormContinue);

    watch(email, () => {
      message.value = ''
    })

    return {
      email,
      message,
      refEmail,
      loader,
      validate,
      errorChecker,
      correctChecker,
      UiIconNames,
    }

  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/landing/auth';
</style>
