<template lang="pug">
.dropdown(:class="!isDropdown && '_clickable'")
  template(v-if="isDropdown")
    button.dropbtn {{ name }}
      ui-icon.dropdown-icon(:icon="UiIconNames.Chevron_Down")
    .dropdown-content
      a(v-for="link of links" :href="link.href" :key="link.href" target="_blank") {{ link.title }}
  template(v-else)
    span.dropbtn {{ name }}
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "HeaderDropdown",
  components: {
    UiIcon,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    links: {
      type: Array as PropType<{ title: string, href: string }[]>,
      default: () => ([]),
    },
    href: {
      type: String,
      default: '#',
    },
    isDropdown: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
$blue-color: #409eff;
$background-color: #ecf5ff;

@mixin flex-center {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

@mixin dropdown-content {
  line-height: 18px;
  float: none;
  color: var(--main-color-black);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: $background-color;
    color: $blue-color;
  }
}

.dropdown {
  float: left;
  overflow: hidden;

  &._clickable {
    display: flex;
    cursor: pointer;
  }
}

.dropdown .dropbtn {
  @include flex-center;
  font-size: 14px;
  padding: 14px 16px;
  background-color: inherit;
  line-height: 18px;
  border: none;
  outline: none;
  color: black;
  font-family: inherit;
  margin: 0;
  white-space: nowrap;
}

.dropdown-content {
  padding: 4px 0;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;

  box-sizing: border-box;
  box-shadow: var(--dialog-border-shadow);
  border-radius: 4px;

  z-index: 10;
}

.dropdown-icon {
  transition:  0.2s ease-out;
}

a:hover, .dropdown:hover .dropbtn {
  color: $blue-color;

  .dropdown-icon {
    transform: rotateZ(180deg);
  }
}

.dropdown-content a {
  @include dropdown-content;
  font-size: 14px;
}

.dropdown:hover .dropdown-content {
  display: block;
}
</style>
