<template lang="pug">
p.fast-auth__paragraph Или быстрая авторизация с помощью:
.fast-auth__icons
  old-tooltip(
    v-for="item in fastAuthIcons"
    :key="item.connect"
    :content="item.tooltip"
  )
    a(target="_blank" :href="getRoute(item.connect)")
      ui-icon(
        clickable
        original-color
        :size="40"
        :icon="item.icon"
      )
</template>

<script lang="ts">
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import OldTooltip from "~/components/ui/tooltip/OldTooltip.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default {
  name: "FastAuth",
  components: {
    UiIcon,
    OldTooltip,
  },
  setup() {

    const fastAuthIcons = [
      { icon: UiIconNames.SocialMedia_Yandex, tooltip: 'Яндекс', connect: 'yandex' },
      { icon: UiIconNames.SocialMedia_Vkontakte, tooltip: 'ВКонтакте', connect: 'vkontakte' },
      { icon: UiIconNames.SocialMedia_Mail, tooltip: 'Mail.ru', connect: 'mail_ru' },
      { icon: UiIconNames.SocialMedia_Google, tooltip: 'Google', connect: 'google' },
    ];

    function getRoute(service: string) {
      return `/api/connect/${service}`
    }

    return {
      fastAuthIcons,
      getRoute,
    };
  },
};
</script>

<style scoped lang="scss">
.fast-auth__paragraph {
  font-size: 14px;
  line-height: 16px;
  margin: 0;
}

.fast-auth__icons {
  display: flex;
  gap: 16px;
  align-self: center;
  flex-wrap: wrap;
}
</style>
